// import { axiosGuest, axiosPrivate } from "./axiosInstance";
import axiosPrivate from "../index";
import queryConcatenator from "../../utils/queryConcatenator";
import API_ENDPOINTS from "../../const/index";

export default {
  getUserProfile(email) {
    return axiosPrivate.get(
      queryConcatenator(API_ENDPOINTS.USER_PROFILE, { email })
    );
  },

  getCities(countryId) {
    return axiosPrivate.get(
      "/public/countries/" + countryId + "?states=yes" + countryId
    );
  },

  addNewEducation(payload) {
    return axiosPrivate.post("/user/education/", payload);
  },
  deleteEducation(id) {
    return axiosPrivate.delete("/user/education/?id=" + id);
  },
  addNewExperience(payload) {
    return axiosPrivate.post("/user/experience/", payload);
  },
  deleteExperience(id) {
    return axiosPrivate.delete("/user/experience/?id=" + id);
  },
  updatePhoto(payload) {
    console.log(payload);
    return axiosPrivate.put("/user/profile-photo", payload);
  },
  updateNysc(payload) {
    return axiosPrivate.put("/user/nysc-status", payload);
  }
};
