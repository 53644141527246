<template>
    <div :class="`tag tag__bg--${background} tag__color--${color}`">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: "Tag",
    props: {
        background: {
            type: String,
            default: "blue",
        },
        color: {
            type: String,
            default: "white",
        },
        text: {
            type: String,
            default: "",
        },
    },
};
</script>

<style scoped lang="scss">
.tag {
    padding: 3px 11px;
    border-radius: 2px;

    &__bg {
        &--blue {
            background: #0084f4;
        }
        &--purple {
            background: #797ef6;
        }
    }
    &__color {
        &--white {
            color: #fff;
        }
        &--black {
            color: #000;
        }
    }
}
</style>
