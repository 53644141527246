<template>
  <div
    class="flex flex__column flex__spaced u-relative"
    style="min-height: 100vh"
  >
    <MobileMenu :active="menuIsChecked" @close="toggleMenu(false)">
      <div>
        <router-link
          class="d-block text__mid-grey fs-20 mb-2"
          :to="{ name: 'jobs' }"
        >
          Jobs
        </router-link>

        <template v-if="userData.token">
          <router-link
            :to="{ name: 'applications' }"
            class="d-block text__mid-grey fs-20 mb-2"
          >
            My Applications
          </router-link>
          <router-link
            :to="{ name: 'my-account' }"
            class="d-block text__mid-grey fs-20 mb-2"
          >
            My Account
          </router-link>
        </template>

        <template v-else>
          <!-- <router-link
            class="d-block text__mid-grey fs-20 mb-2"
            :to="{ name: 'jobs' }"
          >
            Become a recruiter
          </router-link> -->
          <router-link
            class="d-block text__mid-grey fs-20"
            :to="{ name: 'createAccount' }"
          >
            Become a job seeker
          </router-link>
        </template>
      </div>

      <Button theme="blue" @click="handleAuth">
        Log {{ !userData.token ? "in" : "out" }}
      </Button>
    </MobileMenu>
    <Header
      class="pb-2"
      :showLoginBtn="false"
      :headerWithBG="headerWithBG"
      fixed
    >
      <template #links-after>
        <!-- Mobile view -->
        <Dropdown
          class="d-none m-d-block"
          :options="[]"
          @selected="updateSelected"
          :child="true"
        >
          <template #actions>
            <div class="u-relative">
              <button class="p-1" style="outline: none">
                <label class="hamburger" for="check">
                  <input
                    id="check"
                    type="checkbox"
                    ref="ham-checkbox"
                    hidden
                    v-model="menuIsChecked"
                  />
                  <span v-for="n in 3" :key="n" class="hamburger-line" />
                </label>
              </button>
            </div>
          </template>
        </Dropdown>

        <!-- Desktop view logged in -->
        <div
          v-if="userData.token"
          class="m-d-none nav-link__wrapper flex flex__item-center"
        >
          <div class="nav-links">
            <router-link
              :to="{ name: 'jobs' }"
              class="nav-link cursor-pointer fs-16 fw-600"
            >
              Jobs
            </router-link>
            <router-link
              :to="{ name: 'applications' }"
              class="nav-link cursor-pointer fs-16 fw-600"
            >
              My Applications
            </router-link>
            <router-link
              :to="{ name: 'my-account' }"
              class="nav-link cursor-pointer fs-16 fw-600"
            >
              My Account
            </router-link>
          </div>
          <div class="cursor-pointer w-2" style="margin-right: 40px">
            <img
              src="../../assets/icons/notification-icon.svg"
              alt="notification-icon"
            />
          </div>
          <div
            class="cursor-pointer user__profile"
            tabindex="-1"
            @click="showProfileDropdown = !showProfileDropdown"
            @blur="closeProfileDropdown"
          >
            <img :src="avatarIcon" class="avatar-icon" alt="avatar-icon" />
            <div
              class="user__profile-options"
              :class="{ active: showProfileDropdown }"
              v-show="showProfileDropdown"
            >
              <p @click="$router.push({ name: 'account' })">Profile</p>
              <p @click="$refs['user-img'].click()">Upload Photo</p>
              <p @click="forceLogout">Logout</p>

              <input
                type="file"
                ref="user-img"
                hidden
                @change="uploadImg"
                accept=".png, .jpg"
              />
            </div>
          </div>
        </div>

        <!-- Desktop view not logged in -->
        <div v-else class="m-d-none nav-link__wrapper flex flex__item-center">
          <router-link
            :to="{ name: 'jobs' }"
            class="nav-link cursor-pointer fs-16 fw-600"
          >
            Jobs
          </router-link>
          <!-- <router-link
            :to="{ name: 'createAccount' }"
            class="nav-link cursor-pointer fs-16 fw-600"
          >
            Become a Recruiter
          </router-link> -->
          <router-link
            :to="{ name: 'createAccount' }"
            class="nav-link cursor-pointer fs-16 fw-600"
          >
            Become a Job Seeker
          </router-link>
          <Button
            theme="light-blue"
            class="w-16"
            :mobile="true"
            @click="$router.push({ name: 'login' })"
          >
            Login
          </Button>
        </div>
      </template>
    </Header>
    <div class="flex__grow after-header">
      <slot></slot>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import MobileMenu from "../../components/MobileMenu";
import { mapGetters, mapMutations } from "vuex";
import jobseekerService from "../../services/api/jobseekerservice";
import authService from "../../services/api/authService";
import { setPrivateHaders } from "../../services";
import avatarIcon from "../../assets/icons/avatar-icon.svg";
import seekerService from "../../services/api/seekerService";

export default {
  name: "PageLayout",
  props: {
    headerWithBG: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Header,
    Button,
    Footer,
    MobileMenu
  },
  data() {
    return {
      menuIsChecked: false,
      showProfileDropdown: false,
      formData: {
        profilePhoto: ""
      },
      updatingPhoto: false,
      timer: 0
    };
  },
  computed: {
    ...mapGetters({
      userData: "authnew/getUserData",
      profileData: "authnew/getProfileData"
    }),
    avatarIcon() {
      // console.log(this.profileData);
      return this.profileData.profile
        ? this.profileData.profile.profileImage
        : "/img/avatar-icon.svg";
    }
  },
  methods: {
    ...mapMutations({
      logOut: "authnew/logOutUser",
      updateLoggedUser: "auth/updateLoggedUser",
      setUserData: "authnew/setUserData",
      setProfile: "authnew/setProfile",
      setProfileData: "authnew/setProfileData"
    }),
    updateSelected() {},
    toggleMenu(value) {
      this.$refs["ham-checkbox"].checked = value;
      this.menuIsChecked = false;
    },
    closeProfileDropdown() {
      this.showProfileDropdown = false;
    },
    handleAuth() {
      // !this.loggedIn.active
      //   ? this.$router.push({ name: "login" })
      //   : this.logOut();
      !this.userData.token
        ? this.$router.push({ name: "login" })
        : this.logOut();
    },
    forceLogout() {
      this.logOut();
      this.handleAuth();
    },
    uploadImg(e) {
      const fileSize = 200 * 1024;
      const img = new FileReader();
      const vm = this;

      img.onload = (evt) => {
        console.log(evt.total);
        // Max of 5MB
        if (evt.total < fileSize) {
          vm.formData.profilePhoto = evt.target.result;
          let fd = new FormData();
          fd.append("profilePhoto", e.target.files[0]);
          this.updatingPhoto = true;
          seekerService
            .uploadProfilePhoto(fd, (percentage) => {
              console.log(percentage);
            })
            .then((res) => {
              this.$handleSuccess(res);
              this.setProfile(res.data.profile);
              // this.$emit("profileChanged", res.data.profile);
            })
            .catch((e) => {
              this.$handleError(e);
            })
            .finally(() => {
              this.updatingPhoto = true;
            });
        } else {
          this.$handleError("File too large. Max file size allowed is 200KB");
        }
      };
      img.readAsDataURL(e.target.files[0]);
    }
  },
  mounted() {
    const token = this.userData.token;
    if (!token) {
      return;
    }

    // this.loading = true;
    seekerService
      .getSeekerProfile()
      .then((res) => {
        this.setProfileData(res.data);
      })
      .catch((e) => {
        // this.$handleError(e);
      })
      .finally(() => {
        // this.loading = false;
      });
  }
};
</script>

<style scoped>
.avatar-icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.after-header {
  padding-top: 112px;
}
</style>
