<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="grid grid__layout grid__mobile gap-4 no-col-gap mb-3">
        <div class="col-6 m-col-12">
          <span class="d-block text-blue fs-24 fw-600 mb-2">Find Jobs in</span>
          <div class="grid grid__layout gap-6 no-col-gap">
            <div class="col-6 m-col-12">
              <ul class="text__white">
                <li class="mb-2">Business Analyst</li>
                <li class="mb-2">Java Developer</li>
                <li class="mb-2">Sr. Sales Executive</li>
                <li class="mb-2">Product Manager</li>
              </ul>
            </div>
            <div class="col-6 m-col-12">
              <ul class="text__white">
                <li class="mb-2">Sr. Sales Manager</li>
                <li class="mb-2">Fashion Designer</li>
                <li class="mb-2">Sr. Admin</li>
                <li class="mb-2">Sr. Marketing Executive</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6 m-col-12">
          <span class="d-block text-blue fs-24 fw-600 mb-2">Job Locations</span>
          <div class="grid grid__layout gap-6 no-col-gap">
            <div class="col-6 m-col-12">
              <ul class="text__white">
                <li class="mb-2">Nairobi</li>
                <li class="mb-2">Lagos</li>
                <li class="mb-2">Cape Town</li>
                <li class="mb-2">Accra</li>
              </ul>
            </div>
            <div class="col-6 m-col-12">
              <ul class="text__white">
                <li class="mb-2">Kampala</li>
                <li class="mb-2">Maputo</li>
                <li class="mb-2">Casablanca</li>
                <li class="mb-2">Port Elizabeth</li>
                <li></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="footer__top-links flex text__white flex__wrap flex__item-center"
        style="gap: 2.5rem 6.4rem"
      >
        <router-link :to="{ name: 'about-us' }">About us</router-link>
        <router-link to="#">Terms & Conditions</router-link>
        <router-link to="#">Privacy Policy</router-link>
        <router-link to="#">Blogs</router-link>
        <router-link to="#">Careers</router-link>
        <router-link :to="{ name: 'faq' }"> FAQ</router-link>
        <router-link :to="{ name: 'contact-us' }"> Contact us</router-link>
      </div>
    </div>

    <div class="footer__bottom flex flex__wrap flex__spaced flex__item-center">
      <div class="w-7 footer__logo">
        <img
          src="../../assets/icons/infohob-blue-logo-2.svg"
          alt="infohob-logo"
        />
      </div>
      <span class="text__white footer__bottom-copyright"
        >&copy; Copyright 2020 Infohob</span
      >
      <div class="flex">
        <router-link
          to="#"
          class="social__icons rect flex flex__item-center flex__center"
        >
          <img
            src="../../assets/icons/instagram-icon.svg"
            alt="instagram-icon"
          />
        </router-link>
        <a
          href="https://twitter.com/Infohobtech"
          class="social__icons rect flex flex__item-center flex__center"
        >
          <img src="../../assets/icons/twitter-icon.svg" alt="twitter-icon" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="scss">
@use "./footer.scss" as *;
</style>
