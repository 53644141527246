<template>
    <label
        :class="[
            'checkbox flex flex__center flex__item-center',
            `checkbox--${type}`,
            `${checked ? 'checked' : ''}`,
            'cursor-pointer',
        ]"
        :for="id"
    >
        <input type="checkbox" v-model="checked" :id="id" hidden />
        <div class="checkbox__icon" :class="{ checked }">
            <img src="../../assets/icons/check-black.svg" alt="check" />
        </div>
    </label>
</template>

<script>
export default {
    name: "Checkbox",
    props: {
        type: {
            type: String,
            default: "primary",
        },
        id: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            checked: false,
        };
    },
    watch: {
        checked(value) {
            this.$emit("input", value);
        },
    },
};
</script>

<style scoped lang="scss">
@import "./checkbox.scss";
</style>
