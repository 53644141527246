<template>
    <div
        class="mobile-menu u-absolute"
        :class="{ active, 'full-height': fullHeight }"
    >
        <div class="mobile-menu__overlay" @click="$emit('close')" />
        <div class="mobile-menu__card flex flex__column flex__spaced">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button";

export default {
    name: "MobileMenu",
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Button,
    },
    computed: {
        ...mapGetters({
            loggedIn: "auth/getLoggedIn",
        }),
    },
    watch: {
        active: {
            handler(value) {
                if (value) {
                    document.body.style.overflow = "hidden";
                    return;
                }

                document.body.style.overflow = "auto";
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="scss">
.mobile-menu {
    height: 100vh;
    width: 100%;
    pointer-events: none;
    position: fixed;
    top: 0;
    z-index: 50;

    &__overlay {
        height: 100%;
        background: #1414149c;
        opacity: 0;
        transition: 0.3s all ease-out;
        z-index: 10;
    }

    &__card {
        position: absolute;
        bottom: 0;
        padding: 44px 39px;
        width: 428px;
        height: 363px;
        border-radius: 16px 16px 0px 0px;
        width: 100%;
        background: #fff;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transform: translateY(100%);
        transition: 0.6s all ease-out;
    }

    &.active {
        pointer-events: auto;

        & .mobile-menu__overlay {
            height: 100%;
            background: rgba(0, 0, 0, 0.579);
            opacity: 1;
        }

        & .mobile-menu__card {
            bottom: 0;
            opacity: 1;
            transform: translateY(0%);
            pointer-events: auto;
        }
    }

    &.full-height {
        & .mobile-menu__card {
            height: 90vh;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }
}
</style>
